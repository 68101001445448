import { Record } from "immutable";
import { Auth } from "@campus/records";
import app from "./app";
import { composeReducer } from "../utils";
import mapcompose from "./map";
import listcompose from "./list";
import recordcompose from "./record";
import tablescompose from "./tables";
import tablesdef from "../tables";


const INIT = "@@INIT";

//const lists = listcompose<string|{name: string}>("list");
export const globals = mapcompose<string, any>("globals");
export const campus = recordcompose("records", app.CampusRecord);
export const session = recordcompose("session", Auth.Session);

// Compose tables
export const tables = tablescompose(tablesdef)

const RootStates = {
    app: app.state,
    campus: campus.root,
    tables: tables.root,
    globals: globals.root,
    session: session.root,
};

export class RootStateRecord extends Record(RootStates, "root") { }

export const state = new RootStateRecord({});

export type Root = InstanceType<typeof RootStateRecord>;

const reducers = {
    app: composeReducer(app.reducers, app.state),
    campus: composeReducer(campus.reducers, campus.root),
    tables: composeReducer(tables.reducers, tables.root),
    globals: composeReducer(globals.reducers, globals.root),
    session: composeReducer(session.reducers, session.root),
};

export type Store = keyof typeof reducers;

const stores = Object.keys(reducers) as Store[];

function validatePartition(state: Root, value: Store) {
    if (!state.has(value)) {
        throw Error("Unkown store partition " + value);
    }
    if (!(value in reducers)) {
        throw Error("No reducers defined for store partition " + value);
    }
}

function reducer(root: Root=state, action: Store.Action){
    return root.withMutations((root) => {
        stores.forEach((partition: Store) => {
            validatePartition(root, partition);
            const current = root.get(partition)!;
            const reducer = reducers[partition];
            const next = reducer(current as any, action as Store.Action);
            root.set(partition, next);
        });
    });
}

export default { state, reducer }
