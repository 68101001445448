import { Descendant } from 'slate';

export function createRoot(value: string = ""): Descendant[]{
    return [
        {
            type: 'paragraph',
            children: [
                { 
                    type: "text", 
                    text: value, 
                    attr: new Map<string, any>() 
                }
            ],
            attr: new Map<string, any>(),
        },
    ]
}
