import { Record } from "immutable";
import { fork } from "redux-saga/effects";

const INIT = "";

const watch = ({ effect, type, handle }: Store.IHandler) =>
    function* watcher() {
        yield effect(type, handle);
    };


export function forkhandles(handles: Store.IHandler[]) {
    const watchers = handles.map(watch);
    return [...watchers].map(fork);
}

export function composeReducer<T>(
    handlers: Store.IReducerMap<T>,
    defaultState: T
): (state: T, action: Store.Action) => T {
    type ActionType = keyof typeof handlers;

    const actions: ActionType[] = Object.keys(handlers);

    if (actions.includes(INIT)) {
        return (state: T = defaultState, action: Store.Action): T => {
            if (actions.includes(action.type)) {
                return handlers[action.type](state, action);
            } else {
                return state;
            }
        };
    } else {
        return (state: T = defaultState, action: Store.Action): T => {
            const { type } = action;

            if (type === INIT) {
                return defaultState;
            } else if (actions.includes(type)) {
                return handlers[type](state, action);
            } else {
                return state;
            }
        };
    }
}

/** 
 * @todo
 * abstract out the Root creation process reducer
type IReducer = <T=any, A=any>(state: T, action: any) => T

type IReducers = {[key: string]: IReducer} & object

export function createRootReducer<U extends IReducers, Root extends Record<U>>(root: Root, reducers: IReducers){

    type Partition = keyof IReducers;

    const stores = Object.keys(reducers as any) as Partition[];

    function validatePartition(state: Record<U>, value: Partition) {
        if (!state.has(value as any)) {
            throw Error("Unkown store partition " + (value as any));
        }
        if (!(value in (reducers as any))) {
            throw Error("No reducers defined for store partition " + (value as any));
        }
    }

    return function rootReducer(state = root, action: Store.Action) {
        return state.withMutations((state) => {
            stores.forEach((partition: Partition) => {
                validatePartition(state, partition);
                const current = state.get(partition)!;
                const reducer = reducers[partition];
                const next = reducer(current as any, action as Store.Action);
                state.set(partition, next);
            });
        });
    }

}
*/