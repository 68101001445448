import Table from "../table";

export abstract class Public<T=any> extends Table<T> {
    get schema() {
        return "public";
    }
}

export class Countries extends Public {
    get read() {
        return "countries";
    }
    get write() {
        return this.read;
    }
    get records(){
        return this.read;
    }
    get fields() {
        return ["id", "isdn", "name", "icon"];
    }
}

export default {
    countries: Countries,
};