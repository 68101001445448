import React from "react";
import { Descendant, createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'



const initialValue : Descendant[] = [
    {
        type: 'paragraph',
        children: [
            { 
                type: "text", 
                text: '', 
                attr: new Map<string, any>() 
            }
        ],
        attr: new Map<string, any>(),
    },
]

interface IEditor{
    id?: string;
    placeholder?: string;
    onChange?: (value: Descendant[]) => void
    className?: string;
    value?: Descendant[];
    style?: React.CSSProperties;
}

function Textarea({onChange, value, ...props}: IEditor) {
    const [svalue, setValue] = React.useState(value ?? initialValue)
    const [editor] = React.useState(() => withReact(createEditor()))

    React.useEffect(() => {
        if(value && value !== svalue){
            setValue(value)
        }
    }, [value])

    function handleChange(value: Descendant[]) {
        if(onChange){ 
            onChange(value)
        }
    }

    function handleKeyDown(_event: React.KeyboardEvent) {
    }

    return (
        <div {...props}>
            <Slate editor={editor} initialValue={svalue} onChange={handleChange}>
                <Editable onKeyDown={handleKeyDown} className="w-full h-full h-fit focus:outline-none p-1"/>
            </Slate>
        </div>
    )
}

export default Textarea;
