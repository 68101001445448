import React from "react";
import { Descendant, Node, createEditor } from 'slate'
import { createRoot } from "./utils";
import { Slate, Editable, withReact } from 'slate-react'


interface IInput{
    id?: string;
    placeholder?: string;
    onChange?: (value: string) => void
    className?: string;
    value?: string;
    style?: React.CSSProperties;
    onSubmit?: (value: string) => void;
}

const initialValue : Descendant[] = createRoot()

function serialize(nodes: any[]): string {
  return nodes.map(node => Array.isArray(node.children) ? serialize(node.children) : (node.text ?? "") ).join()
}

function Textarea({onChange, value, onSubmit, ...props}: IInput) {
    const [svalue, setValue] = React.useState(initialValue)
    const [editor] = React.useState(() => withReact(createEditor()))

    React.useEffect(() => {
        const evalue = serialize(svalue)
        if(value && value !== evalue){
            setValue(createRoot(value))
        }
    }, [value])

    function handleChange(value: Descendant[]) {
        if(onChange){ 
            onChange(serialize(value))
        }
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if(event.key === 'Enter'){
            event.preventDefault()
        }
    }

    return (
        <div {...props}>
            <Slate editor={editor} initialValue={svalue} onChange={handleChange}>
                <Editable onKeyDown={handleKeyDown} className="w-full h-full h-fit focus:outline-none p-1"/>
            </Slate>
        </div>
    )
}

export default Textarea;
