import React, { useState } from "react";
import { Routes, Route, Link} from 'react-router-dom';
import logoimg from "../assets/images/levelcampus-logo-orange.png"
//import loginimg from "../assets/images/login-image.jpg"
import googleimg from "../assets/images/google.png"
import facebookimg from "../assets/images/facebook.png"
import googleplayimg from "../assets/images/googleplay.png"
import Soon from "../Soon";
import { useAuth } from "@campus/store";

interface IAuth {
    children: React.ReactNode
}

const OAUTH_PROVIDERS = [ 
    {
        name: "google",
        icon: googleimg, 
    }, 
    { 
        name: "facebook", 
        icon: facebookimg
    }
];

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
}

function Input(props: IInput) {
    return (
        <input {...props} className={"form-input w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 bg-white focus:shadow focus:ring-primary-700"}/>
    );
}

function PasswordResetForm() {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    async function handleSubmit(e: React.FormEvent) {
        setLoading(true)
        e.preventDefault();
        e.stopPropagation();
        const target = e.target as typeof e.target & {
          username: { value: string };
          phone: { value: string };
        };

        const username = target.username.value; // typechecks!
        const phone = target.phone.value; // typechecks!
        await auth.login(username, phone);
        setLoading(false);
    }

    return (
        <form onSubmit={handleSubmit} className="w-full flex flex-col space-y-6 py-8 md:border-slate-100 rounded-md md:border-1 px-8 max-w-96">
            <Input type="username" name="username" placeholder="Username"/>
            <Input type="phone" name="phone" placeholder="Phone"/>
            <button disabled={loading} className="w-full py-2 bg-primary-700 text-white rounded-lg shadow font-bold text-sm focus:outline-0">
                Send
            </button>
            <div className="flex flex-row justify-end text-sm">
                <Link className="px-2 text-primary-800" to="/">
                    I know my password
                </Link>
            </div>
        </form>
    );
}

function EmailAuthForm() {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    async function handleSubmit(e: React.FormEvent) {
        setLoading(true)
        e.preventDefault();
        e.stopPropagation();
        const target = e.target as typeof e.target & {
          username: { value: string };
          password: { value: string };
        };

        const username = target.username.value; // typechecks!
        const password = target.password.value; // typechecks!
        await auth.login(username, password);
        setLoading(false);
    }
    return (
        <form onSubmit={handleSubmit} className="flex flex-col w-full space-y-6 py-8 md:border-slate-100 rounded-md md:border-1 px-8 max-w-96">
            <Input type="username" name="username" placeholder="Username"/>
            <Input type="password" name="password" placeholder="Password"/>
            <div className="flex flex-row justify-end text-sm">
                <Link className="px-2 text-primary-800" to="password-reset">
                    Forgot password?
                </Link>
            </div>
            <button disabled={loading} className="w-full py-2 bg-primary-700 text-white rounded-lg shadow font-bold text-sm focus:outline-0">
                Sign In
            </button>
        </form>
    );
}

export interface IPane {
    title: string;
    helper: string;
    children: any;
}

function Pane(props: IPane){
    return (
        <React.Fragment>
            <div className="flex flex-col space-y-4 py-4">
                <span className="text-3xl font-bold">{props.title}</span>
                <span className="text-sm text-gray-600">
                    {props.helper}
                </span>
            </div>
            {props.children}
        </React.Fragment>
    );
}

function Login(){
    return (
        <Pane title="Campus Sign In" helper="Welcome back! Select sign in method">
            <div className="flex flex-row space-x-2">
                {OAUTH_PROVIDERS.map(provider => (
                    <button key={provider.name} className="px-8 py-2 bg-white border border-gray-300 rounded-md">
                        <img src={provider.icon} className="h-8" alt={`oauth login with ${provider.name}`}/>
                    </button>
                ))}
            </div>
            <div className="flex flex-row items-center w-full pt-4">
                <div className="flex-1 h-px bg-slate-300"></div>
                <span className="text-sm text-gray-600 px-4">
                    Sign in with <span className="font-bold px-px text-gray-500">Campus</span> username
                </span>
                <div className="flex-1 h-px bg-slate-300"></div>
            </div>
            <EmailAuthForm />
        </Pane>
    );
}

function PasswordReset(){
    return (
        <Pane title="Forgot your Campus password?" helper="Enter your phone number! and click send to get a reset code:">
            <div className="flex flex-row items-center w-full pt-8">
                <div className="flex-1 h-px bg-slate-300"></div>
                <span className="text-sm text-gray-600 px-4">
                    One Time Reset Code
                </span>
                <div className="flex-1 h-px bg-slate-300"></div>
            </div>
            <PasswordResetForm />
        </Pane>
    );
}


export default function Auth(props: IAuth) {
    const auth = useAuth();
    if(auth.session.is_valid){
        if(process.env.NODE_ENV === "production"){
            return <Soon/>
        }
        return <>{props.children}</>;
    }

    return (
        <div className="w-full h-full flex flex-row bg-amber-50/50 overflow-hidden">
            <div className="flex-1 flex-col bg-amber-600 justify-center hidden md:flex">
                <span className="text-white font-black text-7xl px-32 text-center">
                    Connecting Educators with Students
                </span>
            </div>
            <div className="flex-1 flex flex-col justify-center items-center md:space-y-8 w-min">
                <div className="flex flex-col justify-center items-center">
                    <img src={logoimg} className="h-24" alt="Level Campus"/>
                </div>
                <Routes>
                    <Route path="/" Component={Login}/>
                    <Route path="/password-reset" Component={PasswordReset}/>
                </Routes>
                <div className="flex flex-col justify-center items-center pt-8">
                    <a href="https://play.google.com/store/apps/details?id=com.chedev.levelcampus">
                        <img src={googleplayimg} className="h-10" alt="Google playstore"/>
                    </a>
                </div>
            </div>
        </div>
    );
}
