import React, {useContext} from "react";
import { Icon, useScreen } from "@campus/ui";
import LayoutContext from "./context";
import { useAuth } from "@campus/store";

export interface IHeader {}

export function Account() {
    const auth = useAuth();
    return (
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-col items-end overflow-hidden">
                <span className="font-black text-sm text-gray-800 dark:text-primary-100">
                    {auth.user.name}
                </span>
                <span className="text-xs text-gray-500 font-semibold">1:30am</span>
            </div>
            <img src={auth.user.image_path ?? ""} alt="" className="rounded-full w-8 h-8 md:w-10 md:h-10 bg-blue-500"/>
        </div>
    );
}

export function Notification() {
    const auth = useAuth();
    return(
        <div className="relative">
            <button onClick={auth.logout}>
                <Icon icon="notification" className="w-7 h-7 dark:text-white"/>
            </button>
            <div className="absolute -top-1 -right-1 rounded w-4 h-4 rounded-full bg-red-800 text-white text-xs font-bold text-center">
                8
            </div>
        </div>
    );
}

export function Logo() {
    return (
        <div className="font-bold text-lg bg-primary-900 rounded w-24 h-8"/>
    );
}

export default function Header(_props: IHeader){
    const screen = useScreen();
    const layout = useContext(LayoutContext);
    const tabletOrDesktop = screen.tablet || screen.desktop;
    return (
        <header className="h-14 md:h-16 w-full flex flex-row justify-between items-center overflow-hidden">
            <div className="px-4 md:px-8 flex flex-row items-center space-x-3">

                {screen.mobile && (
                <button onClick={layout.sidebar.toggle}>
                    <Icon icon="menu" className="w-6 h-6 dark:text-gray-200"/>
                </button>
                )}

                {(tabletOrDesktop) && (<div className="w-full flex flex-row pl-3"><Logo /></div>)}

            </div>
            <div className="flex flex-row justify-end px-2 items-center text-gray-600 space-x-10">
                <Notification />
                {(tabletOrDesktop) && <Account/>}
            </div>
        </header>
    );
}
