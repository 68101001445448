import { put, takeEvery, select } from "redux-saga/effects";
import { session} from "../reducers"; 
import * as Actions from "../actions";

const SIGNED_IN = "SIGNED_IN";

function* init(action: Actions.Auth.LoginAction, ctx: SagaContext){
    ctx.client.auth.onAuthStateChange((event: string, sess: any) => {
        if(event === SIGNED_IN){
            let { user } = sess;
            let extra = {user_id: user.id, is_anonymous: user.is_anonymous, email: user.email}
            let payload = {...sess, ...extra}
            ctx.dispatch(session.actions.fill(payload));
        }
    });
}

function* login(action: Actions.Auth.LoginAction, ctx: SagaContext){
    const username = action.payload.username
    const password = action.payload.password
    const email = `${username}@levelcampus.com`
    try{
        const { data, error} = yield ctx.client.auth.signInWithPassword({email, password})
        if( error){
            throw error
        }
    }catch(e: any){
        action.resolve.error(e)
    }

}

function* logout(action: Actions.Auth.LogoutAction, ctx: SagaContext){
    try{
        yield put(session.actions.reset());
        const { data, error} = yield ctx.client.auth.signOut()
        if( error){
            throw error
        }
    }catch(e: any){
        action.resolve.error(e)
    }

}

export default [
    { effect: takeEvery, type: Actions.App.APP_INIT, handler: init},
    { effect: takeEvery, type: Actions.Auth.AUTH_LOGIN, handler: login},
    { effect: takeEvery, type: Actions.Auth.AUTH_LOGOUT, handler: logout},
];

