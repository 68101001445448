import React from "react";
import { useParams } from "react-router-dom";
import { Textarea } from '@campus/ui';


export default function Course(props: any){
    const {course} = useParams();
    const [description, setDescription] = React.useState("");

    return (
        <div className="w-full h-full flex flex-col p-4 md:p-8 space-y-8 overflow-hidden">
            <div className="">
                <span className="font-bold text-2xl dark:text-gray-100">{course}</span>
            </div>
            <div className="relative h-full overflow-y-auto">
                <Textarea.Input value={description} onChange={setDescription} className="text-md"/>
            </div>
        </div>
   );
}
