import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Router from "../Router";
import { Drawer } from "@campus/ui";
import { useScreen } from "@campus/ui";
import pages from "../pages";
import {default as Context, init} from "./context";

const SIDE_PAGES = Object.values(pages)

export default function Layout() {
    const screen = useScreen();
    const [open, setOpen] = React.useState(false);
    const [context, setContext] = React.useState(init);
    React.useEffect(()=>{
        const context = {
            sidebar: {
                opened: !open,
                open: () => setOpen(true),
                close: () => setOpen(false),
                toggle: () => setOpen(!open)
            }
        }

        setContext(context);
    }, [open])

    return (
    <Context.Provider value={context}>
        <div id="layout" className="app flex flex-col dark:bg-slate-900 bg-amber-50/50 overflow-hidden dark:text-gray-100 text-md flex-1">
            <div className="flex-1 flex flex-col relative overflow-hidden items-center">
                <div className="flex-1 flex flex-col max-w-[1900px] w-full">
                    <Header /> 
                    <main className="relative flex flex-1 flex-row flex-end overflow-hidden text-md" id="main">
                        {(screen.tablet || screen.desktop) && <Sidebar pages={SIDE_PAGES}/>}
                        <div className="flex-1 flex flex-col rounded-t-lg border-slate-200 bg-white border shadow-md overflow-hidden md:mt-2">
                            <Router />
                        </div>
                    </main>
                    {screen.mobile && (
                    <Drawer open={open} root="layout" position="left" className="flex flex-row h-full bg-amber-50" onClose={context.sidebar.close}>
                        <Sidebar pages={SIDE_PAGES} />
                    </Drawer>
                    )}
                </div>
            </div>
        </div>
    </Context.Provider>
   );
}

