import { put, takeEvery, select } from "redux-saga/effects";
import * as Actions from "../actions/app";
import { tables } from "../reducers"

function* read(action: any, ctx: SagaContext){
    try {
        let { data, error } = yield ctx.client.tables[action.metadata.table].get(action.payload);
        //tables.actions[action.metadata.action as  keyof typeof tables.actions]
        if (error){
            throw error
        }
        yield action.resolve.success(data);
        yield put(tables.actions.loaded(action.metadata.table, data, {id: data.id}));
    } catch (e: any){
        console.error(e);
        yield action.resolve.error(e);
    }
}

function* load(action: any, ctx: SagaContext){
    try {
        let { data, error } = yield ctx.client.tables[action.metadata.table].list(action.payload);
        //tables.actions[action.metadata.action as  keyof typeof tables.actions]
        if (error){
            throw error
        }
        yield action.resolve.success(data);
        yield put(tables.actions.loaded(action.metadata.table, data));
    } catch (e: any){
        console.error(e);
        yield action.resolve.error(e);
    }
}

function* create(action: any, ctx: SagaContext){
    try {
        let { data, error } = yield ctx.client.tables[action.metadata.table].create(action.payload);
        //tables.actions[action.metadata.action as  keyof typeof tables.actions]
        if (error){
            throw error
        }
        yield action.resolve.success(data);
        yield put(tables.actions.updated(action.metadata.table, data.id, data));
    } catch (e: any){
        console.error(e);
        yield action.resolve.error(e);
    }
}

function* update(action: any, ctx: SagaContext){
    try {
        let { data, error } = yield ctx.client.tables[action.metadata.table].update(action.payload);
        //tables.actions[action.metadata.action as  keyof typeof tables.actions]
        if (error){
            throw error
        }
        yield action.resolve.success(data);
        yield put(tables.actions.updated(action.metadata.table, data.id, data));
    } catch (e: any){
        console.error(e);
        yield action.resolve.error(e);
    }
}

function* trash(action: any, ctx: SagaContext){
    try {
        let { data, error } = yield ctx.client.tables[action.metadata.table].delete(action.payload);
        //tables.actions[action.metadata.action as  keyof typeof tables.actions]
        if (error){
            throw error
        }
        yield action.resolve.success(data);
        yield put(tables.actions.dropped(action.metadata.table, action.payload));
    } catch (e: any){
        console.error(e);
        yield action.resolve.error(e);
    }
}

const creates = tables.tables.map((table) => {
    return {
        effect: takeEvery,
        type: (tables.op as any)[table].create.toString(),
        handler: create
    }
});

const reads = tables.tables.map((table) => {
    return {
        effect: takeEvery,
        type: (tables.op as any)[table].read.toString(),
        handler: read
    }
});

const loads = tables.tables.map((table) => {
    return {
        effect: takeEvery,
        type: (tables.op as any)[table].load.toString(),
        handler: load
    }
});

const updates = tables.tables.map((table) => {
    return {
        effect: takeEvery,
        type: (tables.op as any)[table].update.toString(),
        handler: update
    }
});

const trashes = tables.tables.map((table) => {
    return {
        effect: takeEvery,
        type: (tables.op as any)[table].delete.toString(),
        handler: trash
    }
});

export default [
    ...creates,
    ...reads,
    ...loads,
    ...updates,
    ...trashes,
];

