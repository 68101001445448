import React from "react";

export default function Mobile(){
    return (
        <div>
            Teacher
        </div>
    )
}

