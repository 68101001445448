import { Record, OrderedMap, isImmutable } from 'immutable';
import { createAction, createIOAction } from '../action';


export type BaseTableProps<T> = {
    row: T;
    name: string;
    rows: OrderedMap<string, T>;
}

export interface BaseTableTableMethods<T> {
    put(key: string, value: T): Table<T>
    clear() : Table<T> 

    patch(key: string, patches: T): Table<T>

    drop(key: string): Table<T> 
    find(key: string, def?: T): T | undefined 
    findOrDefault(key: string, def?: T):  T
}

export type BaseTable<T> = BaseTableProps<T> & BaseTableTableMethods<T>

export type Table<T> = Record<BaseTableProps<T>> & BaseTable<T>

export type TablesOf<T extends Object> = Record<{[K in keyof T]: Table<T[K] extends new (...args: any[]) => infer R ? R : never>}>

export function create<T extends Record<T>>(row: T, name: string): ReturnType<typeof Record<BaseTable<T>>> {
    type Row = Record<T>;
    const schema = {
        row: row,
        name: name,
        rows: OrderedMap<string, Row>(),
    }

    const defaultRow: Row = new (row as any)({});

    class TableDef extends Record(schema, name) implements BaseTableTableMethods<Row>{

        put(key: string, value: Object) {
            // @ts-ignore
            return this.updateIn(["rows", key], old => old ? old.merge(value) : TableDef.make(value));
        }

        clear() {
            console.log("Clearing table", name);
            return this.set("rows", OrderedMap<string, Row>())
        }

        patch(key: string, patches: Object){
            // @ts-ignore
            if(this.rows.has(key)) {
                // @ts-ignore
                return this.updateIn(["rows", key], (value: any) => value.merge(patches));
            }
            return this;
        }

        drop(key: string){
            // @ts-ignore
            return this.updateIn(["rows"], (rows) => rows.delete(key));
        }

        find(key: string, def?: Row){
            return this.rows.get(key, def);
        }

        findOrDefault(key: string, def?: Row){
            return this.find(key, def)  ?? defaultRow;
        }

        static make(data: Object): Row {
            let raw : any;
            if(isImmutable(data)) {
                raw = data.toJSON();
            } else {
                raw = data;
            }
            return new (row as any)(raw);
        }

    }
    return TableDef as any;
}

const reducers = {

    cleared<T extends Object>(table: Table<T>) {
        return table.clear();
    },

    loaded<T extends Object>(table: Table<T>, id: string, payload: any) {
        if(Array.isArray(payload) && (id === undefined || id === null)) {
            return payload.reduce((table, row) => table.put(row.id, row), table);
        }
        if(id == undefined || id == null) {
            throw new Error("Invalid id");
        }
        return table.put(id, payload);
    },

    dropped<T extends Object>(table: Table<T>, id: string|string[]) {
        if(Array.isArray(id)) {
            return id.reduce((table, id) => {
                return table.drop(id);
            }, table);
        }
        if(typeof id === "function") {
            const rows = (table as unknown as BaseTableProps<T>).rows.filter(id);
            return table.set("rows", rows);
        }
        if(typeof id === "object" && (id as any).id) {
            return table.drop((id as any).id);
        }
        if(typeof id === "string") {
            return table.drop(id);
        }
        throw new Error("Invalid id");
    },

    updated<T extends Object>(table: Table<T>, id: string, payload: any) {
        if(table.rows.has(id)) {
            if(typeof payload === "function") {
                let row = table.find(id);
                let nextval = payload(row);
                if(nextval instanceof (table as any).row) {
                    return table.setIn(["rows", id], nextval);
                }
                throw new Error(`Invalid return value from ${payload} Must return a new ${table.row} object`);
            } 
            return table.updateIn(["rows", id], (row: any) => row.merge(payload));
        }
        return table;
    },

    set<T extends Object>(table: Table<T>, id: string, field: string|number, payload: any) {
        if((table as any).rows.has(id) && (table as any).rows.get(id).has(field)) {

            // Need to create a swap variable to avoid
            // having the value reference iself as
            // the payload if it is a function
            let value = payload;
            if(typeof payload !== "function") {
                value = () => payload; 
            }
            return table.updateIn(["rows", id, field], value);
        }
        return table;
    }
    
}

const handlers = Object.keys(reducers);


export default function compose<Schema extends Object>(tables: Schema) {

    let tablenames = Object.keys(tables);

    const layout = Object.entries(tables).reduce((acc, [name, row]) => {
        const table = create<any>(row, name);
        acc[name] = new table({});
        return acc;
    }, {} as {[key: string]: any});

    class Tables extends Record(layout, "tables") {}

    class Op {
        __parts: string[];
        constructor(parts: string[]=[]) {
            this.__parts = parts;
            return new Proxy(this, (this as any));
        }
        get(_lhs: any, type: string) {
            let val = (this as any)[type];
            if(val) {
                return val;
            }
            if(typeof type == "string") {
                if(this.__parts.length == 0) {
                    if(tablenames.includes(type)) {
                        return new Op([...this.__parts, type])
                    }
                    throw new Error(`Invalid table ${type}`);
                }
                if(this.__parts.length == 1) {
                    return (new Op([...this.__parts, type])).toString();
                }
            }
        }

        toArray(){
            return this.__parts;
        }

        toString() {
            return `@table/${this.__parts.join("/")}`;
        }
    }

    const builder = new Op();

    function dispatch<T extends Object>(state: Table<T>, action: any, handle: string): Table<T> {
        const { metadata } = action;
        if(metadata) {
            if(handle === "cleared") {
                return reducers[handle](state);
            }
            if(handle === "set" && metadata.field && metadata.id) {
                let { field, id } = metadata;
                return reducers[handle](state, id, field, action.payload);
            }
            if(handle === "updated" && metadata.id) {
                let { id } = metadata;
                return reducers[handle](state, id, action.payload);
            }
            if(handle === "dropped" && metadata.id) {
                return reducers[handle](state, metadata.id ?? action.payload);
            }
            if(handle === "loaded" && metadata.id) {
                let { id } = metadata;
                return reducers[handle](state, id, action.payload);
            }
        }
        if(handle === "loaded" && (action.payload.id || Array.isArray(action.payload)) ) {
            if(Array.isArray(action.payload)) {
                return action.payload.reduce((ntable: any, row: any) => {
                    if(row && row.id) {
                        return reducers[handle](ntable, row.id, row);
                    }
                    return ntable;
                }, state);
            }
            return reducers[handle](state, action.payload.id, action.payload);
        }
        return state;
    }

    return {
        get op(){
            return new Op();
        },
        get root(): TablesOf<Schema> {
            return new Tables({}) as any;
        },
        get tables() {
            return tablenames;
        },
        actions: {
            set(table: string, id: string, field: string, payload: any, meta={}) {
                const action = (builder as any)[table].set.toString();
                return createAction(action, payload, {...meta, field, table, action, id});
            },
            loaded(table: string, payload: any, meta={}) {
                const action = (builder as any)[table].loaded.toString();
                return createAction(action, payload, {...meta, action, table});
            },
            updated(table: string, id: string, payload: any, meta={}) {
                const action = (builder as any)[table].updated.toString();
                return createAction(action, payload, {...meta, table, action, id});
            },
            dropped(table: string, id: string, meta={}) {
                const action = (builder as any)[table].dropped.toString();
                return createAction(action, {id}, {...meta, table, action, id});
            },
            cleared(table: string) {
                const action = (builder as any)[table].cleared.toString();
                return createAction(action, {}, {table});
            },

            read(table: string, params: any, meta={}) {
                const action = (builder as any)[table].read.toString();
                return createIOAction(action, params, {...meta, table, action});
            },
            create(table: string, payload: any, meta={}) {
                const action = (builder as any)[table].create.toString();
                return createIOAction(action, payload, {...meta, table, action});
            },
            load(table: string, params: any, meta={}) {
                const action = (builder as any)[table].load.toString();
                return createIOAction(action, params, {...meta, table, action});
            },
            update(table: string, id: any, payload: any, meta={}) {
                const action = (builder as any)[table].update.toString();
                return createIOAction(action, {id, payload}, {...meta, table, action});
            },
            drop(table: string, id: any, meta={}) {
                const action = (builder as any)[table].drop.toString();
                return createIOAction(action, {id}, {...meta, table, action});
            },
        },
        reducers: tablenames.reduce((acc, name) => {

            return handlers.reduce( (acc, handle) => {
                const handler = (tables: TablesOf<Schema>, action: any) => {
                    const table = dispatch((tables as Tables).get(name)!, action, handle);
                    return (tables as Tables).set(name, table) as TablesOf<Schema>;
                }
                return {...acc, [`@table/${name}/${handle}`]: handler};
            }, acc);

        }, {} as {[key: string]: (state: TablesOf<Schema>, action: any) => TablesOf<Schema>})

    }
     
}




