import color from "color";
import colors from "tailwindcss/colors";

export interface IPalette {
    50:  string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
}

export type IShade = keyof IPalette;

export const palette: {[key: string]: IPalette } = colors as any as {[key: string]: IPalette }

export function apply(theme: string, palette: IPalette, root: HTMLElement = document.documentElement) {
    if (typeof palette === "object") {
        for (let shade in palette) {
            const [r, g, b] = color(palette[shade as any as IShade]).rgb().array();
            root.style.setProperty(`--color-${theme}-${shade}-r`, String(r));
            root.style.setProperty(`--color-${theme}-${shade}-g`, String(g));
            root.style.setProperty(`--color-${theme}-${shade}-b`, String(b));
        }
    }
}

const theme = { apply, palette, get(name: string): IPalette | undefined { return palette[name as any] } };

export default theme;
