import { Record } from 'immutable';

export class CampusRecord extends Record({
    locale: "en_US",
    lpack: {},
    name: "Campus Name",
}) {}

export class CampusAttendance extends Record({
    id: "",
    notes: "",
    type: 0,
    user_id: "",
    school_id: "",
    learner_id: "",
    session_id: "",
    created_at: "",
}) {}


export class CampusEvent extends Record({
    id: "",
    name: "",
    description: "",
    start_date: "",
    stop_date: "",
    school_id: "",
    location_id: "",
    created_at: "",
}) {}

export class CampusSchool extends Record({
    id: "",
    cc: "",
    name: "",
    title: "",
    image_path: null,
    owner_id: "",
    data: {},
    color: "black",
    location: "",
    description: "",
    created_at: "",
}) {}

export class CampusContact extends Record({
    id: "",
    name: "",
    phone: "",
    priority: 0,
    school_id: "",
    student_id: "",
    created_at: "",
}){}

export class CampusDiscipline extends Record({
    id: "",
    name: "",
    school_id: "",
    created_at: "",
    description: "",
    image_path: null,
}) {}

export class CampusSubject extends Record({
    id: "",
    name: "",
    color: "",
    school_id: "",
    discipline_id: "",
    image_path: null,
    description: "",
    created_at: "",
}) {}

export class CampusCourse extends Record({
    id: "",
    name: "",
    weight: 0.0,
    image_path: null,
    description: "",
    is_published: false,
    term_id: "",
    level_id: "",
    subject_id: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusTopic extends Record({
    id: "",
    name: "",
    number: 0,
    description: "",
    course_id: "",
    school_id: "",
    subject_id: "",
    created_at: "",
}) {}

export class CampusUser extends Record({
    id: "",
    bio: "",
    title: "",
    last_name: "",
    middle_name: "",
    first_name: "",
    image_path: null,
    school_id: "",
    created_at: "",
}) {

    get name() {
        return this.names;
    }

    get names() {
        return (`${this.title ?? ""} ${this.first_name ?? ""} ${this.last_name ?? ""}`).trim();
    }

    get fullnames() {
        return (`${this.first_name ?? ""} ${this.middle_name ?? ""} ${this.last_name ?? ""}`).trim();
    }
}

export class CampusLearner extends Record({
    id: "",
    last_name: "",
    middle_name: "",
    first_name: "",
    term_id: "",
    classroom_id: "",
    school_id: "",
    image_path: null,
    student_id: "",
    created_at: "",
}){
    get name() {
        return `${this.first_name} ${this.last_name}`;
    }

    get names() {
        return `${this.first_name} ${this.last_name}`;
    }

    get fullnames() {
        return `${this.first_name} ${this.middle_name} ${this.last_name}`;
    }
}

export class CampusRequest extends Record({
    id: "",
    user_id: "",
    school_id: "",
    created_at: "",
    approved_at: "",
}) {}

export class CampusCycle extends Record({
    id: "",
    name: "",
    start_date: "",
    end_date: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusTerm extends Record({
    id: "",
    name: "",
    is_active: false,
    start_date: "",
    stop_date: "",
    capacity: 0,
    school_id: "",
    created_at: "",
}) {}

export class CampusSession extends Record({
    id: "",
    name: "",
    end_at: "",
    start_at: "",
    classroom_id: "",
    lesson_id: "",
    school_id: "",
    user_id: "",
    created_at: "",
}) {}

export class CampusLecture extends Record({
    id: "",
    name: "",
    end_at: "",
    user_id: "",
    term_id: "",
    start_at: "",
    classroom_id: "",
    topic_id: "",
    course_id: "",
    lesson_id: "",
    school_id: "",
    subject_id: "",
    created_at: "",
}) {}

export class CampusInstructor extends Record({
    id: "",
    level: 9999999,
    term_id: "",
    user_id: "",
    classroom_id: "",
    level_id: "",
    member_id: "",
    course_id: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusMembership extends Record({
    id: "",
    user_id: "",
    role_id: "",
    suspended: false,
    school_id: "",
    created_at: "",
}) {}

export class CampusLesson extends Record({
    id: "",
    name: "",
    number: 0,
    user_id: "",
    published: -1,
    max_score: 0,
    image_path: null,
    description: "",
    topic_id: "",
    school_id: "",
    created_at: "",
}) {

    get is_approved() {
        return this.published === 1;
    }

    get is_gradable() {
        return this.max_score > 0;
    }

    get is_published() {
        return this.published === 1;
    }
    
    get is_pending() {
        return this.published === 0;
    }

    get is_draft() {
        return this.published === -1;
    }

    is_author(user_id?: string) {
        return this.user_id === user_id;
    }
}

export class CampusMember extends Record({
    id: "",
    user_id: "",
    role_id: "",
    metadata: {},
    notes: "",
    school_id: "",
    created_at: "",
}) {
    is_user(user_id?: string) {
        return this.user_id === user_id;
    }
}

export class CampusLevel extends Record({
    id: "",
    name: "",
    level: 0,
    school_id: "",
    created_at: "",
}) {}

export class CampusClassroom extends Record({
    id: "",
    name: "",
    capacity: 0,
    term_id: "",
    level_id: "",
    description: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusRole extends Record({
    id: "",
    name: "",
    type: 0,
    color: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusPermission extends Record({
    id: "",
    caps: [],
    school_id: "",
    created_at: "",
}) {}

export class CampusEducator extends Record({
    id: "",
    caps: [],
    user: {},
    course: {},
    school: {},
    user_id: "",
    member_id: "",
    classroom_id: "",
    course_id: "",
    school_id: "",
    created_at: "",
}) {}

export class CampusStudent extends Record({
    id: "",
    image_path: null,
    dob: "",
    data: {},
    last_name: "",
    first_name: "",
    middle_name: "",
    classroom_id: "",
    school_id: "",
    created_at: "",
    is_archived: true,
    is_suspended: true,
}) {

    get name() {
        return `${this.first_name} ${this.last_name}`;
    }

    get names() {
        return `${this.first_name} ${this.last_name}`;
    }

    get fullnames() {
        return `${this.first_name} ${this.middle_name} ${this.last_name}`;
    }
}

export class CampusResult extends Record({
    id: "",
    score: 0,
    notes: "",
    user_id: "",
    school_id: "",
    session_id: "",
    learner_id: "",
    created_at: "",
}) {
    
}

export class CampusMySchedule extends Record({
    id: "",
    name: "",
    classroom_id: "",
    user_id: "",
    lesson_id: "",
    school_id: "",
    start_at: "",
    end_at: "",
    created_at: "",
    topic_id: "",
    course_id: "",
    member_id: "",
    subject_id: "",
}) {}
