import Client from "./client";
import public_ from "./tables/public";
import campus from "./tables/campus";
import campus_views from "./tables/campus_views";
import account from "./tables/account";

const client =  new Client({
    ...campus, 
    ...campus_views, 
    ...account, 
    ...public_
})
export default client;
