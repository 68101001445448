import React, {useState} from "react";
import { createPortal } from "react-dom";
import { useTransition, animated } from "@react-spring/web";
import { computePosition as setPostition, shift, flip} from "@floating-ui/dom";

const disposable = 'ui-popover';

export interface IPopoverState {
    open: boolean;
}

export type TPlacement = "top" | "top-start" | "top-end" | 
                        "bottom" | "bottom-start" | "bottom-end" | 
                        "left" | "left-start" | "left-end" | 
                        "right" | "right-start" | "right-end";

export interface IPopover<T extends HTMLElement> extends React.HTMLAttributes<T> {
    /**
     * State of the drawer
     * @default false
     * @type boolean
     */
    open?: boolean;

    as?: React.ElementType;

    trigger?: HTMLElement | string | React.RefObject<HTMLElement>;

    /**
     * The ID of the element that will be used as the root container for the drawer.
     * @default document.body
     * @type string | HTMLElement
     */
    anchor: HTMLElement | string | React.RefObject<HTMLElement>;

    /**
     * The ID of the element that will be used as the root container for the drawer.
     * @default document.body
     * @type string | HTMLElement
     */
    root?: HTMLElement | string | React.RefObject<HTMLElement> | null ;

    /**
     * Callback fired when the state is changed.
     * Signature:   function(state: IPopoverState) => void
     * state:       The new state of the popover
     */
    onView?: (state: IPopoverState) => void;

    /**
     * The placement of the drawer
     * @default left
     * @type "left" | "right" | "top" | "bottom"
     */
    placement?: TPlacement;

    /**
     * The duration of the drawer animation
     * @default 150
     * @type number
     */
    duration?: number;

    /**
     * Callback fired when the component requests to be closed.
     * Signature:   function(reason: string, event: object) => void
     * reason:      Can be:`"backdropClicked"` or `"escapeKeyDown @todo"`
     */
    onClose?: (reason: string, el: Event) => void;
}

function getElement(root: HTMLElement | string |  React.RefObject<HTMLElement> | undefined): HTMLElement | undefined | null {
    if (typeof root === "string") {
        return document.getElementById(root);
    } else if (root instanceof HTMLElement) {
        return root;
    } else if ((root instanceof Object) && root.hasOwnProperty("current") && (root.current instanceof HTMLElement)) {
        return root.current;
    }
}

// This is a helper function to get the root element for the component
function getRoot(root: HTMLElement | string | React.RefObject<HTMLElement> | undefined | null): HTMLElement | undefined {
    if (typeof root === "string") {
        const node = document.getElementById(root);
        if(node){
            return node;
        }
    } else if (root instanceof HTMLElement) {
        return root;
    } else if ((root instanceof Object) && root.hasOwnProperty("current") && (root.current instanceof HTMLElement)) {
        return root.current;
    }
}

const defaultStyle: React.CSSProperties = {
    position: "absolute",
}

function computePosition(anchor: HTMLElement, dropdown: HTMLElement, placement: TPlacement = "bottom") {
    const action = setPostition(anchor, dropdown, {
        placement: placement,
        middleware: [flip(), shift()]
    });
    action.then(({ x, y }) => {
            Object.assign(dropdown.style, {
                top: `${y}px`,
                left: `${x}px`
            });
        });
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}

export default React.forwardRef<HTMLElement, IPopover<HTMLElement>>( 
    ({ root, trigger, duration=150, onClose, anchor, placement="bottom-start", ...props }, ref)  => {
        const [mount, setMount] = useState(1);

        const rootEl = getRoot(root);

        const anchorEl = getElement(anchor);

        const triggerEl = getElement(trigger);

        const dropdownRef = React.useRef<HTMLDivElement>(null);

        const [open, setOpen] = useState(Boolean(props.open));

        const ready = isSet(props.open) || isSet(triggerEl);

        // const config = { duration };

        // const transition = useTransition(open, {...positions[position], config, keys: null });

        // Cleanup the root element if it is disposable
        
        React.useEffect(() => {
            if(!ready){
                const value = setTimeout(() => {
                    setMount(mount + 1);
                }, mount)
                return () => {
                    clearTimeout(value);
                }
            }
        }, [ready, mount]);

        React.useEffect(() => {
            if(props.onView&& ready){
                props.onView({open});
            }
        }, [open]);

        React.useEffect(() => {
            if(typeof props.open === "boolean"){
                setOpen(props.open);
            } else if (Boolean(triggerEl)){
                const handle = () => {
                    setOpen(!open);
                }
                triggerEl!.addEventListener("click", handle);
                return () => {
                    triggerEl!.removeEventListener("click", handle);
                }
            }
        }, [open, triggerEl, mount]);

        React.useEffect(() => {
            return () => {
                if (rootEl && rootEl.tagName === disposable.toUpperCase()) {
                    rootEl.remove();
                }
            }
        }, [rootEl]);

        React.useEffect(() => {
            const dropdownEl = dropdownRef.current;
            if (open && Boolean(anchorEl) && Boolean(dropdownEl)) {
                const handleResize = () => {
                    computePosition(anchorEl!, dropdownEl!, placement);
                }
                window.visualViewport!.addEventListener('resize', handleResize);
                return () => {
                    window.visualViewport!.removeEventListener('resize', handleResize);
                }
            }
        }, [dropdownRef.current, anchorEl, open, placement]);

        React.useEffect(() => {
            const dropdownEl = dropdownRef.current;
            if (open && Boolean(anchorEl) && Boolean(dropdownEl)) {
                computePosition(anchorEl!, dropdownEl!, placement);
            }
        }, [dropdownRef.current, anchorEl, open, placement]);


        React.useEffect(() => {
            const handlePositionUpdate = () => {
                if (open && Boolean(anchorEl) && Boolean(dropdownRef.current)) {
                    computePosition(anchorEl!, dropdownRef.current!, placement);
                }
            };

            document.addEventListener('scroll', handlePositionUpdate);

            return () => {
                document.removeEventListener('scroll', handlePositionUpdate);
            };
        }, [open, anchorEl, dropdownRef]);

        React.useEffect(() => {
            const dropdown = dropdownRef.current!;
            if (open && Boolean(dropdown)) {
                const handle = (event: MouseEvent) => {
                    const target = event.target;
                    if (dropdown.contains(target as Node) || dropdown === target) {
                        return
                    }else if (Boolean(triggerEl)){
                        setOpen(false);
                    }else if (onClose) {
                        onClose("backdropClicked", event);
                    }
                };

                // We need to wait for the next event loop to add the event listener
                setTimeout(() => {
                    document.addEventListener('click', handle);
                }, 0);

                return () => {
                    document.removeEventListener('click', handle);
                };
            }
        }, [open, onClose, dropdownRef.current, triggerEl]);

        if(!open){
            return <React.Fragment/>;

        } else if(rootEl){
            return createPortal(
                React.createElement(props.as || "div", {...props, ref: dropdownRef, style: defaultStyle}, props.children),
                rootEl
            );
        } else {
            return(
                React.createElement(props.as || "div", {...props, ref: dropdownRef, style: defaultStyle}, props.children)
            );

        }

    });

