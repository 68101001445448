import Table from "../table";

export abstract class CampusView<T=any> extends Table<T> {
    get schema() {
        return "campus";
    }
    get is_view() {
        return true;
    }
}

export class MembershipsView extends CampusView {
    get read() {
        return "memberships";
    }
    get records(){
        return "members";
    }
    get fields() {
        return ["id", "name", "user_id", "school_id", "employer_id", "created_at"];
    }
}

export class TeachersView extends CampusView {
    get read() {
        return "teachers";
    }
    get records(){
        return "educators";
    }
    get fields() {
        return ["*"];
    }
}

export class MyCoursesView extends CampusView {
    get read() {
        return "mycourses";
    }
    get records(){
        return "mycourses";
    }
    get fields() {
        return ["*"];
    }
}

export class InstructorsView extends CampusView {
    get read() {
        return "instructors";
    }
    get records(){
        return "instructors";
    }
    get fields() {
        return ["*"];
    }
}

export class LecturesView extends CampusView {
    get read() {
        return "lectures";
    }
    get records(){
        return "sessions";
    }
    get fields() {
        return ["id", "name", "course_id", "topic_id", "subject_id", "term_id", "start_at", "end_at", "user_id", "lesson_id", "classroom_id", "school_id", "created_at"];
    }
}

export class MySchedulesView extends CampusView {
    get read() {
        return "myschedules";
    }
    get records(){
        return "sessions";
    }
    get fields() {
        return ["id", "name", "classroom_id", "user_id", "lesson_id", "school_id", "start_at", "end_at", "created_at", "topic_id", "course_id", "member_id", "subject_id"];
    }
}

export class TermLessonsView extends CampusView {
    get read() {
        return "term_lessons";
    }
    get records(){
        return "lessons";
    }
    get fields() {
        return ["id", "number", "name", "user_id", "max_score", "image_path", "description", "topic_id", "course_id", "term_id", "school_id", "created_at"];
    }
}

export class TermEducatorsView extends CampusView {
    get read() {
        return "term_educators";
    }
    get records(){
        return "educators";
    }
    get fields() {
        return ["id", "caps", "classroom_id", "course_id", "member_id", "school_id", "subject_id", "level_id", "term_id", "user_id", "created_at"];
    }
}

export class TermClassroomsView extends CampusView {
    get read() {
        return "term_classrooms";
    }
    get records(){
        return "classrooms";
    }
    get fields() {
        return ["id", "name", "description", "term_id", "school_id", "level_id", "created_at"];
    }
}

export class TermLearnersView extends CampusView {
    get read() {
        return "term_learners";
    }
    get records(){
        return "learners";
    }
    get fields() {
        return ["id", "image_path", "data", "first_name", "middle_name", "last_name", "classroom_id", "term_id", "level_id", "student_id", "school_id", "created_at"];
    }
}

export class StudentResultsView extends CampusView {
    get read() {
        return "student_results";
    }
    get records(){
        return "results";
    }
    get fields() {
        return ["id", "notes", "score", "weight", "user_id", "school_id", "learner_id", "session_id", "created_at", "topic_id", "classroom_id", "lesson_id", "course_id", "subject_id", "term_id", "max_score", "is_unsealed", "student_id"];
    }
}

export class StudentAttendancesView extends CampusView {
    get read() {
        return "student_attendances";
    }
    get records(){
        return "attendances";
    }
    get fields() {
        return ["id", "notes", "type", "user_id", "school_id", "learner_id", "session_id", "created_at", "topic_id", "classroom_id", "lesson_id", "course_id", "subject_id", "term_id", "max_score", "is_unsealed", "student_id"];
    }
}

export default {
    memberships: MembershipsView,
    teachers: TeachersView,
    mycourses: MyCoursesView,
    instructors: InstructorsView,
    lectures: LecturesView,
    myschedules: MySchedulesView,
    term_lessons: TermLessonsView,
    term_educators: TermEducatorsView,
    term_classrooms: TermClassroomsView,
    term_learners: TermLearnersView,
    student_results: StudentResultsView,
    student_attendances: StudentAttendancesView,
};