import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import * as Actions from "./actions";
import root, { Root } from "./reducers";

export { Root } from "./reducers";
export * from "./records";
export * from "./hooks";
export * as Actions from "./actions";

const sagaMiddleware = createSagaMiddleware();

const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: false})
    : (c: any) => c;

const middlewares = applyMiddleware(sagaMiddleware);

const enhancers = compose(middlewares, devTools);

const store = createStore(root.reducer, root.state, enhancers);

const init = (client: any) => {
    const args = {...store, client: client}
    sagaMiddleware.run(sagas, args);
    store.dispatch(Actions.App.initStore());
}

(store as any).init = init;

export type Store = typeof store;

export default store as Store & { init: typeof init };
