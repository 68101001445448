import React from "react"
import Lottie from "react-lottie"
import windmill from "../assets/lottie/windmill.json"

export interface ISoon {
    slogan?: string
}

const OPTIONS = {
    loop: true,
    autoplay: true,
    animationData: windmill
}

export default function Soon(props: ISoon){
    return (
        <div className="flex flex-col justify-between items-center w-full h-full bg-primary-500">
            <div className="flex-1 flex flex-col justify-around">
                <div className="md:w-max-96 md:h-max-96">
                    <Lottie options={OPTIONS} width="w-full" height="h-full"/>
                </div>
                <div className="flex flex-col">
                    <span className="text-xl uppercase font-black text-white text-center">
                        This webapp is still under construction!
                    </span>
                </div>
            </div>
            <div className="flex flex-col p-8">
                <span className="text-xs text-white text-center">
                    All rights reserved. Unauthorized reproduction, copying, or creation of derivative works from this webapp is strictly prohibited.
                </span>
                <span className="text-xs text-white text-center">
                    © Outburst Media 2024
                </span>
            </div>
        </div>
    );
}
