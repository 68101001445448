import { SupabaseClient} from '@supabase/supabase-js'
import Table from "./table";

export default class Client<C extends Table, Tables extends ({[key: string]: C}| {})> {

    endpoint: SupabaseClient;

    tables: {[K in keyof Tables]: Tables[K] extends new (...args: any[]) => infer R ? R : never};

    constructor(tables: Tables) {
        this.tables = {} as any;
        this.endpoint = null as any;
        for (let [namespace, Unite] of Object.entries(tables)) {
            const config = {namespace}
            //@ts-ignore
            this.tables[namespace] = new Unite(this, config);
        }
    }

    get auth() {
        return this.endpoint.auth;
    }

    setEndpoint(endpoint: SupabaseClient){
        this.endpoint = endpoint;
    }

    schema(name: string) {
        return this.endpoint.schema(name);
    }

}
