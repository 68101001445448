import React from "react";
import { MdFormatAlignCenter as AlignCenter } from "react-icons/md";
import { MdFormatAlignLeft as AlignLeft } from "react-icons/md";
import { MdFormatAlignRight as AlignRight } from "react-icons/md";
import { MdFormatBold as Bold } from "react-icons/md";
import { MdFormatItalic as Italic } from "react-icons/md";
import { MdFormatQuote as Quote } from "react-icons/md";
import { MdFormatStrikethrough as Strikethrough } from "react-icons/md";
import { MdFormatColorFill as ColorFill } from "react-icons/md";
import { MdFormatColorText as ColorText } from "react-icons/md";
import { MdFormatClear as Clear } from "react-icons/md";
import { MdSubscript as Subscript } from "react-icons/md";
import { MdSuperscript as Superscript } from "react-icons/md";
import { MdFormatIndentDecrease as IndentDecrease } from "react-icons/md";
import { MdFormatIndentIncrease as IndentIncrease } from "react-icons/md";
import { MdFormatAlignJustify as AlignJustify } from "react-icons/md";
import { MdFormatListBulleted as ListBulleted } from "react-icons/md";
import { MdFormatListNumbered as ListNumbered } from "react-icons/md";
import { MdFormatUnderlined as Underline } from "react-icons/md";
import { MdFormatTextdirectionRToL as AlignEnd } from "react-icons/md";
import { MdFormatTextdirectionLToR as AlignStart } from "react-icons/md";
import { MdTextIncrease as TextIncreate } from "react-icons/md";
import { MdTextDecrease as TextDecrease } from "react-icons/md";
import { MdAdd as Plus } from "react-icons/md";
import { MdAddchart as Insert } from "react-icons/md";
import { MdRedo as Redo } from "react-icons/md";
import { MdUndo as Undo } from "react-icons/md";
import { MdCode as Code } from "react-icons/md";
import { MdInsertLink as Link } from "react-icons/md";
import { MdGridOn as Table } from "react-icons/md";
import { MdOutlineImage as Image } from "react-icons/md";
import { MdOutlineEmojiEmotions as Emoji } from "react-icons/md";
import { MdTextFields as Paragraph } from "react-icons/md";
import { TbTypography as FormatElement } from "react-icons/tb";
import { LuHeading1 as Heading1 } from "react-icons/lu";
import { LuHeading2 as Heading2 } from "react-icons/lu";
import { LuHeading3 as Heading3 } from "react-icons/lu";
import { LuHeading4 as Heading4 } from "react-icons/lu";
import { LuHeading5 as Heading5 } from "react-icons/lu";
import { LuHeading6 as Heading6 } from "react-icons/lu";
import { MdChecklist as Checklist } from "react-icons/md";
import { MdModeEdit as Write } from "react-icons/md";

const icons : {[key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>} = {
    "editor.align.center": AlignCenter,
    "editor.element.format": FormatElement,
    "editor.write": Write,
    "editor.align.left": AlignLeft,
    "editor.align.right": AlignRight,
    "editor.bold": Bold,
    "editor.plus": Plus,
    "editor.italic": Italic,
    "editor.text.increase": TextIncreate,
    "editor.text.decrease": TextDecrease,
    "editor.undo": Undo,
    "editor.insert": Insert,
    "editor.redo": Redo,
    "editor.underline": Underline,
    "editor.quote": Quote,
    "editor.align.start": AlignStart,
    "editor.align.end": AlignEnd,
    "editor.strikethrough": Strikethrough,
    "editor.color.fill": ColorFill,
    "editor.color.text": ColorText,
    "editor.clear": Clear,
    "editor.subscript": Subscript,
    "editor.superscript": Superscript,
    "editor.indent.decrease": IndentDecrease,
    "editor.indent.increase": IndentIncrease,
    "editor.align.justify": AlignJustify,
    "editor.list.bulleted": ListBulleted,
    "editor.list.numbered": ListNumbered,
    "editor.code": Code,
    "editor.paragraph": Paragraph,
    "editor.heading1": Heading1,
    "editor.heading2": Heading2,
    "editor.heading3": Heading3,
    "editor.heading4": Heading4,
    "editor.heading5": Heading5,
    "editor.heading6": Heading6,
    "editor.checklist": Checklist,
    "editor.link": Link,
    "editor.table": Table,
    "editor.image": Image,
    "editor.emoji": Emoji,
    //"editor.mention": Mention,
    //"editor.mention.slash": MentionSlash,
    //"editor.mention.at": MentionAt,
}

export default icons;
