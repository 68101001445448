import React from "react";
import { Link, Routes, Route } from "react-router-dom";
import Course from "./Course";

export function Card() {
    return (
        <Link to="biolog-101" className="w-full md:max-w-80 min-w-64 flex flex-col rounded-md shadow-md border-2 dark:border-slate-800 border-slate-200 overflow-hidden">
            <div className="h-40 bg-gray-200 dark:bg-slate-800"/>
            <div className="flex flex-col p-4">
            <div className="">
                <span className="text-gray-800 dark:text-gray-100 font-bold text-md ">
                    Biology 101
                </span>
            </div>
            <div>
                <span className="text-gray-500 dark:text-gray-400 font-bold text-sm">
                    3 Sections
                </span>
            </div>
            <div className="dark:text-gray-700 text-gray-500 font-bold text-sm">
                description or something
            </div>
            </div>
        </Link>
    )
}

export function Courses() {
    return (
        <div className="flex flex-col w-full p-4 md:p-8 space-y-8 overflow-hidden">
            <div className="flex flex-row justify-end">
                <button className="rounded-md bg-primary-900 text-white px-4 py-2 ">
                    Add Course
                </button>
            </div>
            <div className="overflow-y-auto">
                <div className="flex flex-row space-y-8 md:space-y-8 flex-wrap md:space-x-8 md:first-child:ml-8 md:first-child:mt-8">
                    <Card/>
                </div>
            </div>
        </div>
    )
} 

export default function Main() {
    return (
        <Routes>
            <Route path="/" Component={Courses}/>
            <Route path="/:course" Component={Course}/>
        </Routes>
    );
}


