import { createContext } from 'react';

export const init = {
    sidebar: {
        opened: false,
        open: () => {},
        close: () => {},
        toggle: () => {}
    }
}

export default createContext(init);
