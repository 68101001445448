import { Record } from 'immutable';

export class User extends Record({
    id: "",
    bio: "",
    title: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    image_path: null,
    school_id: "",
    created_at: "",
}) {

    get name() {
        return this.names;
    }

    get names() {
        return (`${this.title ?? ""} ${this.first_name ?? ""} ${this.last_name ?? ""}`).trim();
    }

    get fullnames() {
        return (`${this.first_name ?? ""} ${this.middle_name ?? ""} ${this.last_name ?? ""}`).trim();
    }
}

