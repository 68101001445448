import { Country, Campus, Account } from "@campus/records";

const tables = {
    countries: Country,
    classrooms: Campus.Classroom,
    disciplines: Campus.Discipline,
    subjects: Campus.Subject,
    levels: Campus.Level,
    students: Campus.Student,
    results: Campus.Result,
    instructors: Campus.Instructor,
    attendances: Campus.Attendance,
    roles: Campus.Role,
    permissions: Campus.Permission,
    educators: Campus.Educator,
    schools: Campus.School,
    courses: Campus.Course,
    topics: Campus.Topic,
    learners: Campus.Learner,
    lessons: Campus.Lesson,
    lectures: Campus.Lecture,
    terms: Campus.Term,
    contacts: Campus.Contact,
    sessions: Campus.Session,
    members: Campus.Member,
    memberships: Campus.Membership,
    users: Account.User,
    requests: Campus.Request,
    myschedules: Campus.MySchedule,
}
export default tables

