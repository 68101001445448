import React from "react";
import { MdDehaze as Menu } from "react-icons/md";
import { RiGroup2Line as Classroom } from "react-icons/ri";
import { MdMenuBook as Leason } from "react-icons/md";
import { PiBooksBold as Course } from "react-icons/pi";
import { MdOutlineRssFeed as Feed } from "react-icons/md";
import { MdCalendarToday as Calendar } from "react-icons/md";
import { MdNotificationsNone as Notification } from "react-icons/md";
import { GrGroup as Student } from "react-icons/gr";
import { FaPeopleGroup as Teacher } from "react-icons/fa6";
import { GiBlackBook as Subject } from "react-icons/gi";
import { GiBookshelf as Curriculum } from "react-icons/gi";
import { GiBookPile as Syllabus } from "react-icons/gi";
import { GrMapLocation as Location } from "react-icons/gr";
import { MdOutlinePermContactCalendar as Semester } from "react-icons/md";
import { MdChevronLeft as ChevronLeft } from "react-icons/md";
import { MdChevronRight as ChevronRight } from "react-icons/md";
import { BsThreeDots as ThreeDotsHorizontal } from "react-icons/bs";
import { MdCancel as Cancel } from "react-icons/md";
import { MdCheck as Check} from "react-icons/md";

import editor from "./Editor";

interface IIcon extends React.SVGProps<SVGSVGElement> {
    icon: string; 
}

export const icons = Object.assign({
    "menu": Menu,
    "classroom": Classroom,
    "cancel": Cancel,
    "check": Check,
    "leason": Leason,
    "course": Course,
    "feed": Feed,
    "teacher": Teacher,
    "subject": Subject,
    "threedots.horizontal": ThreeDotsHorizontal,
    "calendar": Calendar,
    "notification": Notification,
    "student": Student,
    "curriculum": Curriculum,
    "syllabus": Syllabus,
    "location": Location,
    "chevron.left": ChevronLeft,
    "chevron.right": ChevronRight,
    "semester": Semester
}, editor);


export default function Icon({name, ...props}: IIcon) {
    const icon = icons[props.icon as keyof typeof icons] as any as React.FC<React.SVGProps<SVGSVGElement>>;
    if(icon === undefined) return <i>Icon not found</i>;
    return React.createElement(icon, props);
}

