import Courses from '../pages/Courses';
import Dashboard from '../pages/Dashboard';
import Calendar from '../pages/Calendar/Mobile';
import Teachers from '../pages/Instructor/Mobile';

const pages = {
    dashboard: {
        root: Dashboard,
        path: "/",
        icon: "feed",
        name: "Dashboard",
    },
    calendar: {
        root: Calendar,
        icon: "calendar",
        path: "calendar",
        name: "Calendar",
    },
    course: {
        root: Courses,
        icon: "course",
        path: "courses",
        name: "Courses",
    },
    teacher: {
        root: Teachers,
        icon: "teacher",
        path: "teachers",
        name: "Teachers",
    },
}

export default pages
