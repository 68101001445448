import { Record } from 'immutable';
import { createAction } from '../action';

interface BaseClass {
    [key: string]: any;
}

const reducers = {

    reset<T extends Object>(map: Record<T>): Record<T>{
        return map;
    },

    set<T extends Object>(map: Record<T>, payload: Object) {
        if (typeof payload === "object") {
            return Object.entries(payload).reduce((map, [key, value]) => {
                if(typeof value == "function") {
                    return map.update(key as any, value); 
                }
                return map.set(key as any, value); 
            }, map);
        }
        return map;
    },

    fill<T extends Object>(map: Record<T>, payload: Object): Record<T> {
        return map.merge(payload as any);
    }
    
}

const handlers = Object.keys(reducers);


export default function compose<T extends abstract new (...args: any[]) => Record<any>, U=ReturnType<InstanceType<T>["toObject"]>>(name: string, rclass: T){
    type Root = InstanceType<T>
    let sample  = new (rclass as any)({}) as Root
    class Op {
        __parts: string[];
        constructor(parts: string[]=[]) {
            this.__parts = parts;
            return new Proxy(this, (this as any));
        }
        get(_lhs: any, type: string) {
            let val = (this as any)[type];
            if(val) {
                return val;
            }
            if(typeof type == "string") {
                if(this.__parts.length == 0) {
                    if(type === name) {
                        return new Op([...this.__parts, type])
                    }
                    throw new Error(`Invalid record ${type}`);
                }
                if(this.__parts.length == 1) {
                    if(type.match(handlers.join("|"))) {
                        return (new Op([...this.__parts, type])).toString();
                    }
                    throw new Error(`Invalid operation ${type}`);
                }
            }
        }

        toArray(){
            return this.__parts;
        }

        toString() {
            return `@record/${this.__parts.join("/")}`;
        }
    }

    const builder = new Op();

    function dispatch(state: Root, action: any, handle: string): Root {
        if(handle === "reset") {
            return sample;
        }
        if(handle === "fill" || handle === "set") {
            return reducers.set(state as any, action.payload) as Root;
        }
        return state;
    }

    return {
        get op(){
            return new Op();
        },
        get root(): Root {
            return new (rclass as any)({})
        },
        actions: {
            fill(payload: Partial<U>, meta={}) {
                const type = (builder as any)[name].set.toString();
                return createAction(type, payload, {...meta});
            },
            set<V>(key: keyof U, value: V, meta={}) {
                const type = (builder as any)[name].set.toString();
                return createAction(type, {[key]: value}, {...meta});
            },
            reset() {
                const type = (builder as any)[name].reset.toString();
                return createAction(type, {}, {});
            }
        },
        reducers: handlers.reduce((acc, handle) => {
            const handler = (map: Root, action: any) => {
                return dispatch(map, action, handle) as Root;
            }
            return {...acc, [`@record/${name}/${handle}`]: handler};

        }, {} as {[key: string]: (state: Root, action: any) => Root})

    }
     
}



