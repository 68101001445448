import { SupabaseClient } from "@supabase/supabase-js";

export type Key = string | number ;

export interface Client {
    schema(name: string): ReturnType<SupabaseClient["schema"]>
}

export type ClientObject<T=any> = {[key: Key]: T}|{}

export type QueryObject<T=string|number> = {[key: string]: T|T[]}|{}

export type ClientResponse<T> = Promise<{data: T, error?: QueryObject}>

export interface IConfig {
    namespace: string;
}

function match<T=any>(table: T, fields: ClientObject<any>, search: ClientObject<number|string|string[]>={}){
    let query = Object.entries(fields).reduce((acc, [key, value]) => { 
        if(Array.isArray(value)){

            return (acc as any).in(key, value);

        } else if (value === false) {

            return (acc as any).eq(key, false);

        } else if (value === null) {

            return (acc as any).eq(key, null);

        } else if (Boolean(value)) {

            return (acc as any).eq(key, value);

        } else {

            return acc;

        }
    }, table);
    return Object.entries(search).reduce((acc, [key, value]) => {
        return (acc as any).textSearch(key, value);
    }, query);
}


export default abstract class Table<E=any>{

    protected client: Client;

    protected config: IConfig;

    constructor(client: Client, config: IConfig) {
        this.client = client;
        this.config = config;
    }

    get name() {
        return this.config.namespace;
    }

    get table() {
        return this.name;
    }

    get schema() {
        return "public";
    }

    get read() {
        return "public";
    }

    get write() {
        return "public";
    }

    get records(){
        return "public";
    }

    get reader() {
        return this.client.schema(this.schema).from(this.read);
    }

    get writer() {
        return this.client.schema(this.schema).from(this.read);
    }

    get fields(): string[] {
        return ["*"];
    }

    select(selected?: string[]){
        return this.reader.select((selected ?? this.fields).join(","));
    }


    async search(context: ClientObject, query: ClientObject) : ClientResponse<E[]> {
        if(typeof query === "string"){
            query = {name: query};
        }
        return await match(this.select(), context, query) as any;
    }

    async get(id: string | QueryObject): ClientResponse<E>{
        let res: any
        if(typeof id === "object"){
            res = await match(this.select(), id).single();
        } else {
            res = await this.select().eq("id", id).single();
        }
        return res;
    }

    async list(id?: QueryObject) : ClientResponse<E[]> {
        try{
            if(id){
                if(Array.isArray(id)){
                    return await this.select().in("school_id", id) as any;
                } else if (typeof id === "object"){
                    return await match(this.select(), id) as any;
                } else {
                    return await this.select().eq("school_id", id) as any;
                }
            } else {
                return await this.select() as any;
            }
        }catch(e){
            console.error(this.name, "list", id, e);
        }
        return Promise.reject({error: `${this.schema}.${this.name}.list`, payload: id});
    }

    async create(payload: ClientObject) : ClientResponse<E> {
        try{
            let res = await this.writer.insert({...payload}).select("*").single() as any;
            if(res.error){
                return res;
            }
            return res;
        }catch(e){
            console.error(this.name, "create", payload, e);
        }
        return Promise.reject({error: `${this.schema}.${this.name}.create`, payload});
    }

    async update({id, ...payload}: QueryObject & {id: string}) : ClientResponse<E> {
        try{
            let res = await this.writer.update({...payload}).eq("id", id).select(this.fields.join(",")).single() as any;
            if(res.error){
                return res;
            }
            return res;
        } catch(e){
            console.error(this.name, "update", id, payload, e);
        }
        return Promise.reject({error: `${this.schema}.${this.name}.update.${id}`, payload});
    }

    async delete(id: string|string[]) {
        try{
            if(Array.isArray(id)){
                return await this.writer.delete().in("id", id);
            } else {
                return await this.writer.delete().eq("id", id);
            }
        }catch(e){
            console.error(this.name,"delete", id, e);
        }
        return Promise.reject({error: `${this.schema}.${this.name}.delete.${id}`});
    }
}
