import { Record } from 'immutable';
export * as Account from "./account";
export * as Campus from "./campus";
export * as Auth from "./auth";

export class Country extends Record({
    id: "",
    name: "",
    isdn: "",
    icon: "",
}) {}

