import React from "react";
import { Account } from "@campus/records";
import { Root  } from "../reducers";
import { useSelector, useDispatch } from "react-redux";
import * as thooks from "./tables"
import { Auth } from "../actions";
export * from "./tables";

export function useCampus() {
    return useSelector((store: Root) => store.campus)
}

export function useSession() {
    return useSelector((store: Root) => store.session)
}

export function useAuth(){
    const session = useSession();
    const dispatch = useDispatch();
    const {record: user} = thooks.useRecord<Account.User>("users", session.user_id);

    function login(username: string, password: string) {
        const action = Auth.login({username, password})
        return dispatch(action); 
    }

    function reset(username: string, phone: string) {
        const action = Auth.reset({username, phone})
        return dispatch(action); 
    }

    function logout() {
        const action = Auth.logout()
        return dispatch(action); 
    }

    return React.useMemo(()=> {
        return ({session, user, login, reset, logout});
    },[session, user])

}

