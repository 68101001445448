import Table from "../table";

export abstract class Account<T=any> extends Table<T> {
    get schema() {
        return "account";
    }
}

export class Users extends Account {
    get read() {
        return "users";
    }
    get write() {
        return "users";
    }
    get records(){
        return "users";
    }
    get fields() {
        return ["id", "first_name", "middle_name", "title", "last_name", "bio", "image_path", "rni", "created_at"];
    }
}

export default {
    users: Users,
};