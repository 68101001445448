import client from "@campus/client";
import { createClient } from "@supabase/supabase-js";

//@ts-ignore
client.setEndpoint(createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY));

client.endpoint.auth.onAuthStateChange((event, session) => {
  if (event === 'INITIAL_SESSION') {
    // handle initial session
  } else if (event === 'SIGNED_IN') {
    // handle sign in event
  } else if (event === 'SIGNED_OUT') {
        console.log('signed out', session);
    // handle sign out event
  } else if (event === 'PASSWORD_RECOVERY') {
        console.log('pwd recv', session);
    // handle password recovery event
  } else if (event === 'TOKEN_REFRESHED') {
        console.log('tok refresh', session);
    // handle token refreshed event
  } else if (event === 'USER_UPDATED') {
        console.log('user updated', session);
    // handle user updated event
  }
})


export default client
