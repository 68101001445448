import { Record } from "immutable";
import * as Actions from "../actions/app";
import { CampusRecord } from "../records";

export const state = new CampusRecord({});

export const reducers = {
    [Actions.APP_INIT]: (app: CampusRecord, payload: Actions.InitAction) => {
        return app;
    },
};

export default { state, reducers, CampusRecord };
