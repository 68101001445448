import { createAction, createIOAction } from "../../action";

export const AUTH_LOGIN = "AUTH_LOGIN";
export type AUTH_LOGIN = typeof AUTH_LOGIN;
export interface ILoginParams {username: string; password: string}
export type LoginAction = Store.IOAction<AUTH_LOGIN, ILoginParams, any>;
export function login(params: ILoginParams):  LoginAction{
    return createIOAction(AUTH_LOGIN, params);
}

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export type AUTH_LOGOUT = typeof AUTH_LOGOUT;
export type LogoutAction = Store.IOAction<AUTH_LOGOUT, any>;
export function logout():  LogoutAction{
    return createIOAction(AUTH_LOGOUT, {});
}

export const AUTH_REQUEST_RESET_PHONE_CODE = "AUTH_REQUEST_RESET_PHONE_CODE";
export type AUTH_REQUEST_RESET_PHONE_CODE = typeof AUTH_REQUEST_RESET_PHONE_CODE;
export interface IPasswordResetParams {username: string; phone: string}
export type IResetPasswordReqAction = Store.IOAction<AUTH_REQUEST_RESET_PHONE_CODE, IPasswordResetParams, any>;
export function reset(params: IPasswordResetParams):  IResetPasswordReqAction{
    return createIOAction(AUTH_REQUEST_RESET_PHONE_CODE, params);
}
