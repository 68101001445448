import { Record } from 'immutable';

export class Session extends Record({
    access_token: "",
    token_type: "",
    expires_in: 0,
    expires_at: 0,
    user_id: "",
    refresh_token: "",
    is_anonymous: true
}) {
    reset() {
        return new Session({});
    }

    get is_valid() {
        return !this.is_anonymous &&  Boolean(this.access_token.trim()) && Boolean(this.refresh_token.trim()) && Boolean(this.user_id.trim());
    }
}

