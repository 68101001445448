import Table from "../table";

export abstract class Campus<T=any> extends Table<T> {
    get schema() {
        return "campus";
    }
}

export class Schools extends Campus {
    get read() {
        return "schools";
    }
    get records(){
        return "schools"
    }
    get write() {
        return "schools";
    }
    get fields() {
        return ["*"];
    }
}

export class Attendances extends Campus {
    get read() {
        return "attendances";
    }
    get records(){
        return "attendances"
    }
    get write() {
        return "attendances";
    }
    get fields() {
        return ["id", "type", "notes", "user_id", "learner_id", "school_id", "session_id", "created_at"];
    }
}

export class Terms extends Campus {
    get read() {
        return "terms";
    }
    get write() {
        return "terms";
    }
    get records(){
        return "terms"
    }
    get fields() {
        return ["id", "name", "is_active", "start_date", "stop_date", "capacity", "school_id", "created_at"];
    }
}

export class Requests extends Campus {
    get read() {
        return "requests";
    }
    get write() {
        return "requests";
    }
    get records(){
        return "requests"
    }
    get fields() {
        return ["id", "user_id", "school_id", "created_at", "approved_at"];
    }
}

export class Locations extends Campus {
    get read() {
        return "locations";
    }
    get write() {
        return "locations";
    }
    get records(){
        return "locations"
    }
    get fields() {
        return ["id", "name", "school_id", "created_at"];
    }
}

export class Members extends Campus {
    get read() {
        return "members";
    }
    get write() {
        return "members";
    }
    get records(){
        return "members"
    }
    get fields() {
        return ["id", "metadata", "suspended", "image_path", "notes", "role_id", "user_id", "school_id", "created_at"];
    }
}

export class Subjects extends Campus {
    get read() {
        return "subjects";
    }
    get write() {
        return "subjects";
    }
    get records(){
        return "subjects"
    }
    get fields() {
        return ["id", "name", "color", "image_path", "description", "discipline_id", "school_id", "created_at"];
    }
}

export class Disciplines extends Campus {
    get read() {
        return "disciplines";
    }
    get write() {
        return "disciplines";
    }
    get records(){
        return "disciplines"
    }
    get fields() {
        return ["id", "name", "image_path", "description", "school_id", "created_at"];
    }
}

export class Educators extends Campus {
    get read() {
        return "term_educators";
    }
    get write() {
        return "educators";
    }
    get records(){
        return "educators"
    }
    get fields() {
        return ["id", "caps", "classroom_id", "course_id", "member_id", "user_id", "school_id", "created_at"];
    }
}

export class Teachers extends Campus {
    get read() {
        return "teachers";
    }
    get write() {
        return "eductors";
    }
    get records(){
        return "educators"
    }
    get fields() {
        return ["*"];
    }
}

export class Roles extends Campus {
    get read() {
        return "roles";
    }
    get write() {
        return "roles";
    }
    get records(){
        return "roles"
    }
    get fields() {
        return ["id", "name", "type", "school_id", "created_at"];
    }
}

export class Permissions extends Campus {
    get read() {
        return "permissions";
    }
    get write() {
        return "permissions";
    }
    get records(){
        return "permissions";
    }
    get fields() {
        return ["id", "caps", "school_id", "created_at"];
    }
}

export class Courses extends Campus {
    get read() {
        return "courses";
    }
    get write() {
        return "courses";
    }
    get records(){
        return "courses";
    }
    get fields() {
        return ["id", "name", "weight", "description", "level_id", "image_path", "subject_id", "school_id", "created_at", "term_id"];
    }
}

export class Levels extends Campus {
    get read() {
        return "levels";
    }
    get write() {
        return "levels";
    }
    get records(){
        return "levels";
    }
    get fields() {
        return ["id", "name", "level", "school_id", "created_at"];
    }
}

export class Classrooms extends Campus {
    get read() {
        return "classrooms";
    }
    get write() {
        return "classrooms";
    }
    get records(){
        return "classrooms";
    }
    get fields() {
        return ["id", "name", "capacity", "description", "term_id", "school_id", "level_id", "created_at"];
    }
}

export class Topics extends Campus {
    get read() {
        return "topics";
    }
    get write() {
        return "topics";
    }
    get records(){
        return "topics";
    }
    get fields() {
        return ["id", "name", "number", "description", "course_id", "school_id", "created_at"];
    }
}

export class Lessons extends Campus {
    get read() {
        return "lessons";
    }
    get write() {
        return "lessons";
    }
    get records(){
        return "lessons";
    }
    get fields() {
        return ["id", "number", "name", "user_id", "max_score", "image_path", "description", "topic_id", "school_id", "created_at"];
    }
}

export class Sessions extends Campus {
    get read() {
        return "sessions";
    }
    get write() {
        return "sessions";
    }
    get records(){
        return "sessions";
    }
    get fields() {
        return ["id", "name", "start_at", "end_at", "user_id", "lesson_id", "classroom_id", "school_id", "created_at"];
    }
}

export class Contacts extends Campus {
    get read() {
        return "contacts";
    }
    get write() {
        return "contacts";
    }
    get records(){
        return "contacts";
    }
    get fields() {
        return ["id", "name", "phone", "priority", "student_id", "school_id", "created_at"];
    }
}

export class Students extends Campus {
    get read() {
        return "students";
    }
    get write() {
        return "students";
    }
    get records(){
        return "students";
    }
    get fields() {
        return ["id", "first_name", "is_archived", "is_suspended", "middle_name", "last_name", "image_path", "dob", "data", "school_id", "created_at"];
    }
}

export class Learners extends Campus {
    get read() {
        return "learners";
    }
    get write() {
        return "learners";
    }
    get records(){
        return "learners";
    }
    get fields() {
        return ["id", "image_path", "reference_id", "classroom_id", "student_id", "school_id", "created_at"];
    }
}

export class Results extends Campus {
    get read() {
        return "results";
    }
    get write() {
        return "results";
    }
    get records(){
        return "results";
    }
    get fields() {
        return ["id", "score", "notes", "session_id", "learner_id", "user_id", "school_id", "created_at"];
    }
}

export default {
    schools: Schools,
    attendances: Attendances,
    terms: Terms,
    requests: Requests,
    locations: Locations,
    members: Members,
    subjects: Subjects,
    disciplines: Disciplines,
    educators: Educators,
    teachers: Teachers,
    roles: Roles,
    permissions: Permissions,
    courses: Courses,
    levels: Levels,
    classrooms: Classrooms,
    topics: Topics,
    lessons: Lessons,
    sessions: Sessions,
    contacts: Contacts,
    students: Students,
    learners: Learners,
    results: Results,
};
