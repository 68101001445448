import React from 'react';
import Courses from '../pages/Courses';
import Dashboard from '../pages/Dashboard';
import Calendar from '../pages/Calendar/Mobile';
import Instructor from '../pages/Instructor/Mobile';
import { Routes, Route} from 'react-router-dom';

export default function App() {
    return (
        <Routes>
            <Route path="/" Component={Dashboard}/>
            <Route path="/courses/*" Component={Courses}/>
            <Route path="/teachers/*" Component={Instructor}/>
            <Route path="/calendar/*" Component={Calendar}/>
            <Route path="*" element={
                <div className="w-full h-full">
                    <div className="animate-ping bg-slate-900 dark:text-white text-2xl font-bold text-center mt-8">
                        404
                    </div>
                </div>
            }/>
        </Routes>
    )
}
