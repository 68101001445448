import { Root, tables } from "../reducers";
import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ttables from "../tables";
import { OrderedMap } from "immutable";

type Tables = typeof ttables

type Table = keyof Tables;

export interface IRecord<P, T=any> {
    record: P,
    load(meta? : IObject): Promise<T>;
    drop(meta? : IObject): Promise<T>;
    refresh(meta? : IObject): Promise<T>;
    update(payload: any, meta? : IObject): Promise<T>;
    create(payload: any, meta? : IObject): Promise<T>;
}

export interface IRecords<P, T=any> {
    records: OrderedMap<string, P>;
    loading: boolean;
    refresh(meta? : IObject): Promise<T>;
    reload(meta? : IObject): Promise<T>;
}

export type IValue  = number | null | string | {} | number[] | string[] | {}[] |  {[key: string]: IValue} | IValue[]

export interface IObject {
    [key: string]: IValue
}

export function diff(obj1: IObject, obj2: IObject) {
    let keys = Object.keys(obj1);
    let index = keys.findIndex((key) => {
        return obj1[key] !== obj2[key];
    });
    if(index < 0) {
        return null;
    } 
    return keys[index];
}

export function match(obj1: IObject, obj2: IObject){
    return diff(obj1, obj2) === null;
}

export function useRecord<R=InstanceType<Tables[Table]>>(partition: Table, rid: string, anon=true): IRecord<R> {
    const [id, setId] = useState(rid);
    const dispatch = useDispatch();
    const [deleted, setDeleted] = useState(false);
        
    const record = useSelector((state: Root) => {
        return anon ? state.tables.get(partition).findOrDefault(id) : state.tables.get(partition).find(id);
    }) as R;

    useEffect(() => {
        if(rid && rid !== id) {
            setId(rid);
        }
    }, [rid]);
    const composed = useMemo(() => {
        const actions = {
            async refresh(meta={}) {
                if(deleted){
                    throw "deleted"
                }
                if(!Boolean(id)) {
                    return {};
                }
                const action = tables.actions.read(partition, id, meta);
                const {data, error} = await dispatch(action);
                if(error){
                    console.error(partition, error)
                    throw error;
                }
                return data
            },
            async load(meta={}) {
                if(deleted){
                    throw "deleted"
                }
                if(!Boolean(id)) {
                    return {};
                }
                const action = tables.actions.read(partition, id, meta);
                const {data, error} = await dispatch(action);
                if(error){
                    console.error(partition, error)
                    throw error;
                }
                return data
            },
            async update(payload: any, meta={}) {
                if(deleted){
                    throw "deleted"
                }
                if(!Boolean(id)) {
                    return {};
                }
                const action = tables.actions.update(partition, id, payload, meta);
                const {data, error} = await dispatch(action);
                if(error){
                    console.error(partition, error)
                    throw error;
                }
                return data
            },
            async drop(meta={}) {
                if(deleted){
                    throw "deleted"
                }
                if(!Boolean(id)) {
                    return {};
                }
                const action = tables.actions.drop(partition, id, meta);
                const {data, error} = await dispatch(action);
                if(error){
                    console.error(partition, error)
                    throw error;
                }
                setDeleted(true);
                return data;
            },
            async create(payload: any, meta={}) {
                const action = tables.actions.create(partition, payload, meta);
                const {data, error} = await dispatch(action);
                if(error){
                    console.error(partition, error)
                    throw error;
                }
                setDeleted(false)
                return data;
            }
        }
        return {record, ...actions};
    }, [record, id]);   


    useEffect(() => {
        if(((!record && anon == true) || (record && (record as any).id == "")) && !deleted) {
            (composed as any).load().catch(console.warn);
        }
    }, [record, id]);

    return composed as any;
}

export function useRecords<R=InstanceType<Tables[Table]>>(partition: Table, params: IObject={}, auto=true): IRecords<R>{
    if(partition === undefined) {
        throw new Error("Partition is undefined");
    }
    const dispatch = useDispatch();
    const [init, setInit]  = useState(false);
    const [loading, setLoading]  = useState(false);

    const tag = typeof params === "string" ?  params : (new URLSearchParams(params as any)).toString()

    const records = useSelector((root: Root)=> { 
        if(!root.tables.has(partition)){
            throw new Error(`Table ${partition} is undefined`);
        }
        return root.tables.get(partition).rows;
    });

    return useMemo(() => {
        let filtered = records;
        if(params !== undefined && records) {
            let filter: any = () => true;
            switch(typeof params) {
                case "object":
                    const keys = Object.keys(params);
                    filter = (row: any) => {
                        let found = keys.findIndex(key => {
                            const target = params[key];
                            if(Array.isArray(target)){
                                return !target.includes(row.get(key));
                            }
                            return row.get(key) !== target;
                        });
                        if(found < 0) {
                            return true;
                        }
                        return false;
                    }
                    break;

                default:
                    filter = (row: any) => row.school_id === params;
                    break;
            }
            filtered = (records as any).filter(filter);
        }
        async function refresh(meta={}) {
            if(loading == true){
                return [];
            }
            setLoading(true);
            const action = tables.actions.load(partition, params, meta);
            const {data, error} = await dispatch(action);
            setLoading(false);
            if(error){
                console.error(partition, error)
                throw error;
            }
            return data;
        }

        async function reload(meta={}) {
            if(loading == true){
                return [];
            }

            setLoading(true);
            const action = tables.actions.load(partition, params, meta);
            const {data, error} = await dispatch(action);
            setLoading(false);
                // Remove values absent from new 
                // data set
                // if(filtered && filtered.size > 0){
                    //filtered.forEach((val) => {
                        //if(!ids.includes(val.id)){
                            //action = table.actions.drop(db.collection, val.id, meta);
                            //dispatch(action);
                        //}
                    //})
                //}

            if(error){
                console.error(partition, error)
                throw error;
            }
            return data;
        }
        if(init == false && Boolean(filtered) && filtered.size == 0  && loading == false && auto) {
            setInit(true);
            reload();
        }
        return { records: filtered, reload, refresh, loading};
    }, [init, records, tag, loading]) as any;   

}


