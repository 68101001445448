import React from "react";
import { BrowserRouter } from "react-router-dom";
import palette from "./Theme/palette";
import { Provider } from 'react-redux'
import Auth from "./Auth";
import Layout from "./Layout";
import store from "@campus/store";
import client from "./client";
import "./App.css";

//console.log(process.env.NODE_ENV);

store.init(client);
palette.apply("primary", palette.get("blue")!);
palette.apply("secondary", palette.get("red")!);

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter basename="/">
                <Auth>
                    <Layout />
                </Auth>
            </BrowserRouter>
        </Provider>
   );
}

export default App;
