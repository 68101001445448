import React from "react";
import clx from "classnames";
import { Icon } from "@campus/ui";
import { Link, useLocation } from 'react-router-dom';


export interface ISidebar {
    pages: IPath[]
}

export interface IPath {
    name: string;
    icon: string;
    path: string;
}

export interface IItem {
    name: string;
    active?: boolean;
    path: string;
    icon?: string;
}

export function Item(props: IItem){
    return (
    <Link 
        to={props.path} 
        className={clx("rounded-md rounded px-3 py-1 hover:bg-primary-100 flex flex-row space-x-2 items-center dark:hover:bg-primary-600/20", 
                { 
            ["text-gray-500"]: !Boolean(props.active),
            ["text-gray-900 bg-amber-100/50"]: Boolean(props.active)
        })}>
        {props.icon && <Icon icon={props.icon} className="w-5 h-5"/>}
        <div>{props.name}</div>
    </Link>
    );
}

export default function Sidebar(props: ISidebar){
    const {pathname}= useLocation();
    let name = pathname.split("/")[1];
    if(!Boolean(name)){
        name = "/"
    }
    return (
        <div className="w-64 flex flex-col p-8 space-y-1">
            {props.pages.map(punit => <Item key={punit.name} name={punit.name} icon={punit.icon} path={punit.path} active={punit.path === name} />)}
        </div>
    )
}
