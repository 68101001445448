import { put, takeEvery, select } from "redux-saga/effects";
import * as Actions from "../actions/app";

const SIGNED_IN = "SIGNED_IN";

function* init(payload: any, ctx: SagaContext){
    ctx.client.auth.onAuthStateChange((event: string, session: any) => {
        if(event === SIGNED_IN){
            // fill store root session
            ctx.dispatch({type: "@@SIGNED_IN", payload: session});
        }
    });
}

export default [
    { effect: takeEvery, type: Actions.APP_INIT, handler: init },
];
