import { Map} from 'immutable';
import { createAction } from '../action';

const reducers = {

    clear<K=any, V=any>(map: Map<K,V>): Map<K,V>{
        return Map();
    },

    drop<K=string|any, V=any>(map: Map<K, V>, key: K) {
        return map.delete(key); 
    },

    set<K=string|number, V=any>(map: Map<K, V>, payload: {[field: string|number]: V}) {
        if (typeof payload === "object") {
            return Object.entries(payload).reduce((map, [key, value]) => {
                if(typeof value == "function") {
                    return map.update(key as any, value as any); 
                } else {
                    return map.set(key as any, value); 
                }
            }, map);
        }
        return map;
    },

    fill<K=string|number, V=any>(map: Map<K, V>, payload: Object): Map<K, V> {
        return map.merge(payload as any);
    }
    
}

const handlers = Object.keys(reducers);


export default function compose<K=string|number|symbol, V=any>(name: string){

    class Op {
        __parts: string[];
        constructor(parts: string[]=[]) {
            this.__parts = parts;
            return new Proxy(this, (this as any));
        }
        get(_lhs: any, type: string) {
            let val = (this as any)[type];
            if(val) {
                return val;
            }
            if(typeof type == "string") {
                if(this.__parts.length == 0) {
                    if(type === name) {
                        return new Op([...this.__parts, type])
                    }
                    throw new Error(`Invalid table ${type}`);
                }
                if(this.__parts.length == 1) {
                    if(type.match(handlers.join("|"))) {
                        return (new Op([...this.__parts, type])).toString();
                    }
                    throw new Error(`Invalid operation ${type}`);
                }
            }
        }

        toArray(){
            return this.__parts;
        }

        toString() {
            return `@map/${this.__parts.join("/")}`;
        }
    }

    const builder = new Op();

    function dispatch(state: Map<K, V>, action: any, handle: string): Map<K, V> {
        if(handle === "clear") {
            return reducers[handle](state);
        }
        if(handle === "fill" || handle === "set") {
            return reducers.set(state, action.payload);
        }
        if(handle === "drop") {
            return reducers[handle](state, action.payload);
        }
        return state;
    }

    return {
        get op(){
            return new Op();
        },
        get root(){
            return Map<K, V>();
        },
        actions: {
            fill(payload: Object, meta={}) {
                const type = (builder as any)[name].set.toString();
                return createAction(type, payload, {...meta});
            },
            set(key: string, value: any, meta={}) {
                const type = (builder as any)[name].set.toString();
                return createAction(type, {[key]: value}, {...meta});
            },
            drop(key: string, meta={}) {
                const type = (builder as any)[name].drop.toString();
                return createAction(type, key, {...meta});
            },
            clear() {
                const type = (builder as any)[name].clear.toString();
                return createAction(type, {}, {});
            }
        },
        reducers: handlers.reduce((acc, handle) => {
            const handler = (map: Map<K, V>, action: any) => {
                return dispatch(map, action, handle);
            }
            return {...acc, [`@map/${name}/${handle}`]: handler};

        }, {} as {[key: string]: (state: Map<K, V>, action: any) => Map<K, V>})

    }
     
}


